<template>
<div>
  <div>
    <div style="text-align: center;font-size: 25px;font-weight: 600"> 预览</div>
      <div style="word-break: break-all" v-html="content"></div>
  </div>

  <hr style="margin: 25px 0">
  <div>
    <we style="width: 100%" v-model="content" :isClear="false"></we>
  </div>
  <div>
    <div v-text="content">

    </div>
  </div>
</div>
</template>

<script>
import we from '@/components/WANG'

export default {
  components: {
    we
  },
  name: "HtmlRegex",
  data(){
    return{
      content:'',
    }
  }
}
</script>

<style scoped>

</style>